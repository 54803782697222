import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { useGlobalContext } from '../../context'
import './ShoppingList.scss'

import ShoppingListSet from '../ShoppingListSet'
import PDFReport from '../PDFReport'

const ShoppingList = () => {
  const {
    cart,
    currentlyEditing,
    resetAllPicks,
    increaseEditingCounter,
    toggleMenu,
  } = useGlobalContext()
  const [currentSets, setCurrentSets] = useState([])

  const handleReset = () => {
    console.log('handleReset')
    toggleMenu()
    increaseEditingCounter()
    resetAllPicks()
  }

  useEffect(() => {
    setCurrentSets(cart)
  }, [cart])

  return (
    <div className="shopping-list">
      <div className="inline-flex">
        <h2>Huskeseddel</h2>
      </div>

      {currentSets.length < 1 ? (
        <>
          <p className="centered desktop">
            Huskesedlen er tom.
          </p>
          <p className="centered desktop">
            Vælg et materiale ved at klikke på <span className="floating-btn-icon">+</span> for at tilføje det til sedlen. Du kan godt tilføje flere materialetyper til samme ret.
            Du kan downloade huskesedlen når du er klar.
          </p>
          <p className="centered mobile">
            Huskesedlen er tom. Tryk dig igennem guiden, vælg et materiale og klik på <span className="floating-btn-icon">+</span> for at tilføje det til
            sedlen. Du kan tilføje flere materialer til samme ret.
          </p>
        </>
      ) : (
        <div className="scrollable">
          <svg
            width="85%"
            height="3"
            viewBox="0 0 100% 1"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line y1="0.5" x2="100%" y2="0.5" stroke="white" />
          </svg>
          {currentSets
            .filter((set) => set.id === currentlyEditing)
            .map((editingSet) => {
              return (
                <ShoppingListSet key="editing-item-current" {...editingSet} />
              )
            })}
          {currentSets
            .filter((set) => set.id !== currentlyEditing)
            .map((set, index) => {
              return <ShoppingListSet key={index} {...set} />
            })}
          {currentSets.length > 0 && (
            // <DownloadBtn
            //   className="btn-download"
            //   onClick={() => downloadCart()}
            // />
            <PDFReport />
          )}
        </div>
      )}
      <div className="shopper-buttons-mobile">
        <a href="https://www.thehost.dk/emballageguide/" target="_blank" rel="noreferrer">
          <button
            className="link-btn"
            onClick={() => console.log('Om guiden')}
          >
            Om guiden
          </button>
        </a>
        <Link to="/namer">
          <button className="link-btn" onClick={() => handleReset()}>
            Ny søgning
          </button>
        </Link>
      </div>
    </div>
  )
}

export default ShoppingList
