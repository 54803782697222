import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'

import './Shopper.scss'
import { useGlobalContext } from '../../context'
import Card from '../Card'
import ShoppingList from '../ShoppingList'
import { ReactComponent as NextMaterialArrow } from '../../assets/images/nextMaterialArrow.svg'
import { ReactComponent as PrevMaterialArrow } from '../../assets/images/prevMaterialArrow.svg'

const Shopper = ({ topComponent }) => {
  const {
    currentName,
    getSelectedOptions,
    resetAllPicks,
    filteredMaterials,
    increaseEditingCounter,
    currentRating,
  } = useGlobalContext()
  const [sortedFilteredMaterials, setSortedFilteredMaterials] = useState([])

  useEffect(() => {
    let tempMaterials = filteredMaterials
    if (currentRating === 'recyclability') {
      tempMaterials = [...filteredMaterials].sort(
        (a, b) => {
          if (b.recyclability === a.recyclability) {
            return a.id - b.id;
          }
          return b.recyclability - a.recyclability
        }
      )
    }
    else if (currentRating === 'nonrecyclability') {
      tempMaterials.sort((a, b) => {
        if (b.nonrecyclability === a.nonrecyclability) {
          return a.id - b.id;
        }
        return b.nonrecyclability - a.nonrecyclability
      })
    }
    setSortedFilteredMaterials(tempMaterials)
  }, [filteredMaterials, currentRating])

  const handleReset = () => {
    increaseEditingCounter()
    resetAllPicks()
  }

  const [materialIndex, setMaterialIndex] = useState(0)
  const nextMaterial = () => {
    setMaterialIndex((oldIndex) => {
      let index = oldIndex + 1
      if (index > filteredMaterials.length - 1) {
        // index = 0 // Circle back
        index = filteredMaterials.length - 1
      }
      return index
    })
  }
  const prevMaterial = ({ topComponent }) => {
    setMaterialIndex((oldIndex) => {
      let index = oldIndex - 1
      if (index < 0) {
        // index = filteredMaterials.length - 1 // Circle back
        index = 0
      }
      return index
    })
  }

  return (
    <>
      {topComponent}
      <div className="shopper">
        <div className="shopper-container">
          <div className="shopper-text">
            <div className="shopper-recap">
              <h4>
                Emballageanbefaling til: {currentName}
              </h4>
              <p className="desktop">
                Du har valgt:
              </p>
              <ul className="recap-list">
                {getSelectedOptions().map((label, key) => <li key={key}>{label}</li>)}
              </ul>
            </div>
            <p className="desktop">
              Materialetyper mærket med <span>tre blade</span> egner sig bedst til dit valg.
            </p>
            <p className="desktop">
              Klik ”læs mere” hvis du vil læse mere om materialetypen.
            </p>
            <p className="desktop">
              Klik <span className="floating-btn-icon">+</span> hvis du vil tilføje et materiale til din huskeseddel. Huskesedlen kan downloades.
            </p>
            <p className="desktop">
              Klik ”ny søgning”, hvis du vil tilføje endnu en emballageanbefaling til en ret til din huskeseddel.
            </p>
            <p className="mobile">
              Materialetyper mærket med <span>tre blade</span> egner sig bedst til dit valg. Tryk på pilene for at gå til næste eller forrige materiale, tryk på materialet for at se flere detaljer og tryk på <span className="floating-btn-icon">+</span> for at tilføje til din huskeliste.
            </p>
            <div
              className={`${
                sortedFilteredMaterials.length % 3 === 2
                  ? 'item-container desktop add-empty'
                  : 'item-container desktop'
              }`}
            >
              {sortedFilteredMaterials.length > 0 ? (
                sortedFilteredMaterials.map((material) => {
                  return <Card key={material.id} {...material}></Card>
                })
              ) : (
                <>
                  <p>Ingen materialer matchede din søgning.</p>
                  <p>
                    Prøv at fravælge nogle kriterier på step 2 ved at gå tilbage
                    oppe i toppen af siden.
                  </p>
                </>
              )}
            </div>
              <div className="item-container mobile">
              {materialIndex > 0 && (
                <div className="prev" onClick={prevMaterial}>
                  <PrevMaterialArrow />
                </div>
              )}
              {sortedFilteredMaterials.length > 0 ? (
                sortedFilteredMaterials.map((material, index) => {
                  let position = 'nextSlide'
                  if (index === materialIndex) {
                    position = 'activeSlide'
                  }
                  if (
                    index === materialIndex - 1 ||
                    (materialIndex === 0 &&
                      index === filteredMaterials.length - 1)
                  ) {
                    position = 'lastSlide'
                  }

                  return (
                    <div key={material.id} className={('slide ', position)}>
                      <Card {...material}></Card>
                    </div>
                  )
                })
              ) : (
                <>
                  <p>Ingen materialer matchede din søgning.</p>
                  <p>
                    Prøv at fravælge nogle kriterier på step 2 ved at gå tilbage
                    oppe i toppen af siden.
                  </p>
                </>
              )}
              {materialIndex < filteredMaterials.length - 1 && (
                <div className="next" onClick={nextMaterial}>
                  <NextMaterialArrow />
                </div>
              )}
            </div>
          </div>
          <div className="shopper-buttons">
            <Link to="/namer">
              <button
                className="link-btn btn-light"
                onClick={() => handleReset()}
              >
                Ny søgning
              </button>
            </Link>
            <div className="shopping-list-container">
              <ShoppingList />
            </div>
            <a href="https://www.thehost.dk/emballageguide/" target="_blank" rel="noreferrer">
              <button
                className="link-btn"
                onClick={() => console.log('Om guiden')}
              >
                Om guiden
              </button>
            </a>
          </div>
        </div>
        <div className="shopper-buttons-mobile">
          <Link to="/namer">
            <button
              className="link-btn btn-light"
              onClick={() => handleReset()}
            >
              Ny søgning
            </button>
          </Link>
          <a href="https://www.thehost.dk/emballageguide/" target="_blank" rel="noreferrer">
            <button
              className="link-btn btn-light"
              onClick={() => console.log('Om guiden')}
            >
              Om guiden
            </button>
          </a>
        </div>
      </div>
    </>
  )
}

export default Shopper
