import { slide as Menu } from 'react-burger-menu'

import './Sidebar.scss'
import removeItem from '../../assets/images/removeItem.svg'
import listLogo from '../../assets/images/listLogo.svg'
import ShoppingList from '../ShoppingList'
import { useGlobalContext } from '../../context'

const Sidebar = (props) => {
  const { isMenuOpen, stateChangeHandler } = useGlobalContext()

  return (
    <Menu
      id="sidebar"
      right
      isOpen={isMenuOpen}
      onStateChange={(state) => stateChangeHandler(state)}
      width="100%"
      customCrossIcon={<img src={removeItem} alt="close" />}
      customBurgerIcon={<img src={listLogo} alt="open" />}
    >
      <ShoppingList />
    </Menu>
  )
}

export default Sidebar
