import { useState, useEffect } from 'react'

import './Card.scss'
import { useGlobalContext } from '../../context'

import leafEmpty from '../../assets/images/leafEmpty.svg'
import leafFull from '../../assets/images/leafFull.svg'
import tempplasticlogo from '../../assets/images/tempplasticlogo.svg'
import { ReactComponent as TemperatureLogo } from '../../assets/images/temperatureLogo.svg'

const Card = ({
  id,
  simpletitle,
  othernames,
  nonrecyclability,
  recyclability,
  temperature,
  img,
  readmoreurl
}) => {
  const { addToCart, currentRating, getSelectedOptions } = useGlobalContext()
  const [displayDetails, setDisplayDetails] = useState(false)
  const [validRating, setvalidRating] = useState(nonrecyclability)

  const handleAddClick = (e, id, simpletitle, othernames) => {
    e.stopPropagation()
    addToCart(id, simpletitle, othernames, getSelectedOptions())
  }

  useEffect(() => {
    if (currentRating === 'nonrecyclability') {
      setvalidRating(nonrecyclability)
    } else {
      setvalidRating(recyclability)
    }
  }, [currentRating, nonrecyclability, recyclability])

  return (
    <div
      className={`${
        displayDetails ? 'cardContainer details' : 'cardContainer'
      }`}
      onClick={() => setDisplayDetails(!displayDetails)}
    >
      <div className="hover">
        <div className={`${displayDetails ? 'display-on' : 'hover-on'}`}>
          <h4 className="cardHeader">{simpletitle}</h4>
          <p className="other-names">{othernames}</p>
          <div className="details-container">
            <button className="details-btn-container">
              <div className="temperature-content">
                <TemperatureLogo />
                {temperature}
              </div>
            </button>
            <a className="details-btn-container" href={readmoreurl} target="_blank" rel="noreferrer">
              <button>Læs mere</button>
            </a>
          </div>
          <button
            className="floating-btn"
            onClick={(e) => handleAddClick(e, id, simpletitle, othernames)}
          >
            +
          </button>
        </div>
        <div className={`${displayDetails ? 'display-none' : 'hover-off'}`}>
          <h4 className="cardHeader">{simpletitle}</h4>
          <img
            src={'/images/' + img}
            className="cardLogo"
            alt="MaterialLogo"
            onError={(e) => {
              e.target.onerror = null
              e.target.src = tempplasticlogo
            }}
          />
          <div className="cardRating">
            <img
              src={validRating > 0 ? leafFull : leafEmpty}
              className="cardRatingLeaf"
              alt={validRating > 0 ? 'leafFull' : 'leafEmpty'}
            />
            <img
              src={validRating > 1 ? leafFull : leafEmpty}
              className="cardRatingLeaf"
              alt={validRating > 1 ? 'leafFull' : 'leafEmpty'}
            />
            <img
              src={validRating > 2 ? leafFull : leafEmpty}
              className="cardRatingLeaf"
              alt={validRating > 2 ? 'leafFull' : 'leafEmpty'}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Card
