import { Link, useHistory } from 'react-router-dom'
import { useEffect } from 'react'

import './Hero.scss'

const Hero = ({ topComponent }) => {
  const history = useHistory()

  useEffect(() => {
    const keyPressHandler = (event) => {
      if (event.key === 'Enter') {
        history.push('/namer')
      }
    }

    window.addEventListener('keydown', keyPressHandler)
    return () => {
      window.removeEventListener('keydown', keyPressHandler)
    }
  })

  return (
    <>
      {topComponent}
      <div className="hero">
        <p>
          Denne guide giver dig en anbefaling til, hvilken takeaway-emballage
          du bør vælge, hvis du gerne vil gøre noget for vores miljø og klima,
          uden at gå på kompromis med funktionalitet. Den hjælper dig i tre
          steps med at finde ud af, hvilke materialer der i det danske
          affaldssystem er bedst egnet til genanvendelse og derfor mere
          bæredygtige at vælge som emballage til din take away end de
          materialetyper, der ikke kan genanvendes men kun forbrændes.
        </p>
        <p>
          Anvendelse: Vælg én ret (eller én gruppe af ens retter) ad gangen og svar på spørgsmålene på de tre steps
          for at få guidens anbefaling til retten. Lav derefter en ny søgning for
          hver ny ret, du også ønsker en anbefaling til. Du kan gemme
          og printe guidens anbefalinger ved at tilføje dem til huskesedlen på sidste side.
        </p>
        <div className="underlined">
          <a href="https://www.thehost.dk/emballageguide/" target="_blank" rel="noreferrer">
            <p>Læs mere om grundlaget for anbefalingerne</p>
          </a>
        </div>
        <div className="centered">
          <Link to="/namer">
            <button className="link-btn">Start</button>
          </Link>
        </div>
      </div>
    </>
  )
}

export default Hero
