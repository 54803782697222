import { Link, useHistory } from 'react-router-dom'
import { useEffect } from 'react'

import { useGlobalContext } from '../../context'
import './Namer.scss'

const Namer = ({ topComponent }) => {
  const { currentName, setCurrentName } = useGlobalContext()

  const history = useHistory()

  useEffect(() => {
    const keyPressHandler = (event) => {
      if (event.key === 'Enter') {
        if (currentName) {
          history.push('/mcp')
        }
      }
    }

    window.addEventListener('keydown', keyPressHandler)
    return () => {
      window.removeEventListener('keydown', keyPressHandler)
    }
  })

  return (
    <>
      {topComponent}
      <div className="namer">
        <p>
          Hvilken ret eller gruppe af retter leder du efter emballage til?
        </p>
        <input
          type="text"
          value={currentName}
          placeholder="fx salat"
          onChange={(e) => setCurrentName(e.target.value)}
          autoFocus
        />
        <div className="centered">
          {currentName ? (
            <Link to="/mcp">
              <button className="link-btn">Videre</button>
            </Link>
          ) : (
            <button className="disabled-btn link-btn">Videre</button>
          )}
        </div>
      </div>
    </>
  )
}

export default Namer
