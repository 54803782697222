const data = [
  { 

    id: 1, 

    simpletitle: 'PET (plast)', 

    othernames: 'APET, DPET, virgin plast', 

    nonrecyclability: 0, 

    recyclability: 3, 

    temperature: '-40 til 70°C', 

    transparencyavailable: true, 

    goodforliquid: true, 

    goodforcrispness: true, 

    goodforovenreheat: false, 

    readmoreurl: 'https://www.thehost.dk/emballageguide/pet-plast/', 

    img: 'materialPET.svg', 

    tempfrozen: true, 

    tempcold: true, 

    tempneutral: false, 

    tempwarm: false, 

  }, 

  { 

    id: 2, 

    simpletitle: 'rPET (plast)', 

    othernames: 'Genanvendt plast', 

    nonrecyclability: 1, 

    recyclability: 3, 

    temperature: '-40 til 70°C', 

    transparencyavailable: true, 

    goodforliquid: true, 

    goodforcrispness: true, 

    goodforovenreheat: false, 

    readmoreurl: 'https://www.thehost.dk/rpet-plast/', 

    img: 'materialPET.svg', 

    tempfrozen: true, 

    tempcold: true, 

    tempneutral: false, 

    tempwarm: false, 

  }, 

  { 

    id: 3, 

    simpletitle: 'CPET (plast)', 

    othernames: '-', 

    nonrecyclability: 0, 

    recyclability: 3, 

    temperature: '-40 til 220°C', 

    transparencyavailable: true, 

    goodforliquid: true, 

    goodforcrispness: true, 

    goodforovenreheat: false, 

    readmoreurl: 'https://www.thehost.dk/emballageguide/cpet-plast/', 

    img: 'materialPET.svg', 

    tempfrozen: true, 

    tempcold: true, 

    tempneutral: false, 

    tempwarm: false, 

  }, 

  { 

    id: 4, 

    simpletitle: 'PP (plast)', 

    othernames: 'PP CO, PP kridt', 

    nonrecyclability: 0, 

    recyclability: 3, 

    temperature: '-40 til 130°C', 

    transparencyavailable: true, 

    goodforliquid: true, 

    goodforcrispness: true, 

    goodforovenreheat: false, 

    readmoreurl: 'https://www.thehost.dk/emballageguide/pp-plast/', 

    img: 'materialPP.svg', 

    tempfrozen: true, 

    tempcold: true, 

    tempneutral: true, 

    tempwarm: false, 

  }, 

  { 

    id: 5, 

    simpletitle: 'HIPS (plast)', 

    othernames: 'PS', 

    nonrecyclability: 0, 

    recyclability: 1, 

    temperature: '-40 til 70°C', 

    transparencyavailable: true, 

    goodforliquid: true, 

    goodforcrispness: true, 

    goodforovenreheat: false, 

    readmoreurl: 'https://www.thehost.dk/emballageguide/hips-plast/', 

    img: 'materialHIPS.svg', 

    tempfrozen: false, 

    tempcold: true, 

    tempneutral: false, 

    tempwarm: false, 

  }, 

  { 

    id: 6, 

    simpletitle: 'OPS (plast)', 

    othernames: 'PS', 

    nonrecyclability: 0, 

    recyclability: 1, 

    temperature: '0 til 70°C', 

    transparencyavailable: true, 

    goodforliquid: true, 

    goodforcrispness: true, 

    goodforovenreheat: false, 

    readmoreurl: 'https://www.thehost.dk/emballageguide/ops-plast/', 

    img: 'materialOPS.svg', 

    tempfrozen: true, 

    tempcold: true, 

    tempneutral: false, 

    tempwarm: false, 

  }, 

  { 

    id: 7, 

    simpletitle: 'PLA (plast)', 

    othernames: 'Bioplast, polymælkesyre', 

    nonrecyclability: 0, 

    recyclability: 0, 

    temperature: '0 til 40°C', 

    transparencyavailable: true, 

    goodforliquid: true, 

    goodforcrispness: true, 

    goodforovenreheat: false, 

    readmoreurl: 'https://www.thehost.dk/emballageguide/pla-bionedbrydelig-plast/', 

    img: 'materialPLA.svg', 

    tempfrozen: false, 

    tempcold: true, 

    tempneutral: false, 

    tempwarm: false, 

  }, 

  { 

    id: 8, 

    simpletitle: 'PE (plast)', 

    othernames: ' PELD, PEHD, PEMD, PELLD, PE-UHMW ', 

    nonrecyclability: 0, 

    recyclability: 3, 

    temperature: '-20 til 95°C', 

    transparencyavailable: true, 

    goodforliquid: true, 

    goodforcrispness: true, 

    goodforovenreheat: false, 

    readmoreurl: 'https://www.thehost.dk/emballageguide/pe-plast/', 

    img: 'materialPE.svg', 

    tempfrozen: true, 

    tempcold: true, 

    tempneutral: true, 

    tempwarm: false, 

  }, 

  { 

    id: 9, 

    simpletitle: 'Biobaseret PE (plast)', 

    othernames: 'Bio-PE', 

    nonrecyclability: 2, 

    recyclability: 3, 

    temperature: '-20 til 90°C', 

    transparencyavailable: true, 

    goodforliquid: true, 

    goodforcrispness: true, 

    goodforovenreheat: false, 

    readmoreurl: ' https://www.thehost.dk/emballageguide/biobaseret-pe-plast/', 

    img: 'materialPE.svg', 

    tempfrozen: true, 

    tempcold: true, 

    tempneutral: true, 

    tempwarm: false, 

  }, 

  { 

    id: 10, 

    simpletitle: 'Aluminium', 

    othernames: 'Alu, metal', 

    nonrecyclability: 2, 

    recyclability: 3, 

    temperature: '-40 til 350°C', 

    transparencyavailable: false, 

    goodforliquid: true, 

    goodforcrispness: true, 

    goodforovenreheat: true, 

    readmoreurl: 'https://www.thehost.dk/emballageguide/aluminium/', 

    img: 'materialAlu.svg', 

    tempfrozen: true, 

    tempcold: true, 

    tempneutral: true, 

    tempwarm: true, 

  }, 

  { 

    id: 11, 

    simpletitle: 'Glas', 

    othernames: '-', 

    nonrecyclability: 1, 

    recyclability: 3, 

    temperature: '-80 til 250°C', 

    transparencyavailable: true, 

    goodforliquid: true, 

    goodforcrispness: false, 

    goodforovenreheat: true, 

    readmoreurl: 'https://www.thehost.dk/emballageguide/glas/', 

    img: 'materialGlas.svg', 

    tempfrozen: true, 

    tempcold: true, 

    tempneutral: true, 

    tempwarm: true, 

  }, 

  { 

    id: 12, 

    simpletitle: 'Bambus', 

    othernames: ' Bambusfiber, træfiber', 

    nonrecyclability: 3, 

    recyclability: 0, 

    temperature: '-60 til 100°C', 

    transparencyavailable: false, 

    goodforliquid: false, 

    goodforcrispness: true, 

    goodforovenreheat: false, 

    readmoreurl: 'https://www.thehost.dk/emballageguide/bambus/', 

    img: 'materialBambus.svg', 

    tempfrozen: true, 

    tempcold: true, 

    tempneutral: true, 

    tempwarm: false, 

  }, 

  { 

    id: 13, 

    simpletitle: 'Pap/papir', 

    othernames: '-', 

    nonrecyclability: 3, 

    recyclability: 0, 

    temperature: '-20 til 90°C', 

    transparencyavailable: false, 

    goodforliquid: false, 

    goodforcrispness: true, 

    goodforovenreheat: true, 

    readmoreurl: 'https://www.thehost.dk/emballageguide/pap-papir/', 

    img: 'materialPapKarton.svg', 

    tempfrozen: true, 

    tempcold: true, 

    tempneutral: true, 

    tempwarm: true, 

  }, 

  { 

    id: 14, 

    simpletitle: 'Bagasse', 

    othernames: 'Cellulosefiber', 

    nonrecyclability: 3, 

    recyclability: 0, 

    temperature: '0 til 100°C', 

    transparencyavailable: false, 

    goodforliquid: true, 

    goodforcrispness: true, 

    goodforovenreheat: false, 

    readmoreurl: 'https://www.thehost.dk/emballageguide/bagasse/', 

    img: 'materialBagasse.svg', 

    tempfrozen: false, 

    tempcold: true, 

    tempneutral: true, 

    tempwarm: false, 

  }, 

  { 

    id: 15, 

    simpletitle: 'Hvedestrå', 

    othernames: 'Cellulosefiber', 

    nonrecyclability: 3, 

    recyclability: 0, 

    temperature: '0 til 100°C', 

    transparencyavailable: false, 

    goodforliquid: true, 

    goodforcrispness: true, 

    goodforovenreheat: false, 

    readmoreurl: 'https://www.thehost.dk/emballageguide/hvedestraa/', 

    img: 'materialHvedestraa.svg', 

    tempfrozen: false, 

    tempcold: true, 

    tempneutral: true, 

    tempwarm: false, 

  }, 

  { 

    id: 16, 

    simpletitle: 'Hvedeklid', 

    othernames: '-', 

    nonrecyclability: 3, 

    recyclability: 0, 

    temperature: '0 til 100°C', 

    transparencyavailable: false, 

    goodforliquid: true, 

    goodforcrispness: true, 

    goodforovenreheat: false, 

    readmoreurl: 'https://www.thehost.dk/emballageguide/hvedeklid/', 

    img: 'materialHvedeklid.svg', 

    tempfrozen: false, 

    tempcold: true, 

    tempneutral: true, 

    tempwarm: false, 

  }, 

  { 

    id: 17, 

    simpletitle: 'Lamineret bambus', 

    othernames: 'Coatet bambusfiber, træfiber', 

    nonrecyclability: 2, 

    recyclability: 0, 

    temperature: '-60 til 100°C', 

    transparencyavailable: false, 

    goodforliquid: false, 

    goodforcrispness: true, 

    goodforovenreheat: false, 

    readmoreurl: 'https://www.thehost.dk/emballageguide/lamineret-bambus/', 

    img: 'materialBambus.svg', 

    tempfrozen: true, 

    tempcold: true, 

    tempneutral: true, 

    tempwarm: false, 

  }, 

  { 

    id: 18, 

    simpletitle: 'Lamineret pap/papir', 

    othernames: '-', 

    nonrecyclability: 2, 

    recyclability: 0, 

    temperature: '-20 til 130°C', 

    transparencyavailable: false, 

    goodforliquid: false, 

    goodforcrispness: true, 

    goodforovenreheat: false, 

    readmoreurl: 'https://www.thehost.dk/emballageguide/lamineret-papir-pap/', 

    img: 'materialPapKarton.svg', 

    tempfrozen: true, 

    tempcold: true, 

    tempneutral: true, 

    tempwarm: true, 

  }, 

  { 

    id: 19, 

    simpletitle: 'Lamineret bagasse', 

    othernames: 'Coatet cellulosefiber', 

    nonrecyclability: 2, 

    recyclability: 0, 

    temperature: '0 til 100°C', 

    transparencyavailable: false, 

    goodforliquid: true, 

    goodforcrispness: true, 

    goodforovenreheat: false, 

    readmoreurl: 'https://www.thehost.dk/emballageguide/lamineret-bagasse/', 

    img: 'materialBagasse.svg', 

    tempfrozen: false, 

    tempcold: true, 

    tempneutral: true, 

    tempwarm: false, 

  }, 

  { 

    id: 20, 

    simpletitle: 'Lamineret hvedestrå', 

    othernames: 'Coatet cellulosefiber', 

    nonrecyclability: 2, 

    recyclability: 0, 

    temperature: '0 til 100°C', 

    transparencyavailable: false, 

    goodforliquid: true, 

    goodforcrispness: true, 

    goodforovenreheat: false, 

    readmoreurl: 'https://www.thehost.dk/emballageguide/lamineret-hvedestraa/', 

    img: 'materialHvedestraa.svg', 

    tempfrozen: false, 

    tempcold: true, 

    tempneutral: true, 

    tempwarm: false, 

  }, 

  { 

    id: 21, 

    simpletitle: 'Lamineret hvedeklid', 

    othernames: '-', 

    nonrecyclability: 2, 

    recyclability: 0, 

    temperature: '0 til 100°C', 

    transparencyavailable: false, 

    goodforliquid: true, 

    goodforcrispness: true, 

    goodforovenreheat: false, 

    readmoreurl: 'https://www.thehost.dk/emballageguide/lamineret-hvedeklid/', 

    img: 'materialHvedeklid.svg', 

    tempfrozen: false, 

    tempcold: true, 

    tempneutral: true, 

    tempwarm: false, 

  } 
];
export default data;