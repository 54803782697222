import { useState, useEffect } from 'react'

import { useGlobalContext } from '../../context'
import './ShoppingListSet.scss'
import removeItem from '../../assets/images/removeItem.svg'

const ShoppingListSet = ({ id, title }) => {
  const {
    removeFromCart,
    removeFromSet,
    currentlyEditing,
    cart,
    currentName,
    setCurrentName,
  } = useGlobalContext()
  const [isDisabled, setIsDisabled] = useState(true)

  const [currentTitle, setcurrentTitle] = useState(title)
  useEffect(() => {
    if (!isDisabled) {
      setcurrentTitle(currentName)
    }
  }, [currentlyEditing, currentName, isDisabled])

  const [currentPicks, setCurrentPicks] = useState([])
  useEffect(() => {
    let tempCartSet = cart.find((cartSet) => cartSet.id === id)
    if (tempCartSet) {
      setCurrentPicks(tempCartSet.picks)
    }
  }, [id, cart])

  useEffect(() => {
    setIsDisabled(id !== currentlyEditing)
  }, [id, currentlyEditing])

  return (
    <div className="list-container">
      <div className="shopping-list-input">
        <input
          disabled={isDisabled}
          type="text"
          value={currentTitle}
          onChange={(e) => setCurrentName(e.target.value)}
        />
        {isDisabled && (
          <img
            className="btn-remove"
            src={removeItem}
            alt="remove"
            onClick={() => removeFromCart(id)}
          />
        )}
      </div>
      {currentPicks.map((pick, index) => {
        return (
          <div key={index} className="shopping-list-item">
            <p>{pick.simpletitle}</p>
            {!isDisabled && (
              <img
                className="btn-remove"
                src={removeItem}
                alt="remove"
                onClick={() => removeFromSet(pick.id)}
              />
            )}
          </div>
        )
      })}
      <svg
        width="100%"
        height="1"
        viewBox="0 0 100% 1"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line y1="0.5" x2="100%" y2="0.5" stroke="white" />
      </svg>
    </div>
  )
}

export default ShoppingListSet
