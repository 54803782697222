import { Link, useHistory } from 'react-router-dom'
import { useEffect } from 'react'

import { useGlobalContext } from '../../context'
import './MultipleChoicePicker.scss'
import { ReactComponent as TransparentLogo } from '../../assets/images/transparentLogo.svg'
import { ReactComponent as CrispLogo } from '../../assets/images/crispLogo.svg'
import { ReactComponent as LiquidLogo } from '../../assets/images/liquidLogo.svg'

const MultipleChoicePicker = ({ topComponent }) => {
  const {
    currentCheckedBox1,
    currentCheckedBox2,
    currentCheckedBox3,
    currentCheckedBox5,
    setCurrentCheckedBox1,
    setCurrentCheckedBox2,
    setCurrentCheckedBox3,
    setCurrentCheckedBox5,
    whenEaten,
    setFoodHeater,
    setWhenEaten,
    currentFoodTemp,
    setCurrentFoodTemp,
  } = useGlobalContext()

  const history = useHistory()

  useEffect(() => {
    const keyPressHandler = (event) => {
      if (currentFoodTemp && event.key === 'Enter') {
        history.push('/dcp')
      }
    }

    window.addEventListener('keydown', keyPressHandler)
    return () => {
      window.removeEventListener('keydown', keyPressHandler)
    }
  })

  const setTemperature = (temperature) => {
    setCurrentFoodTemp(temperature);
    setCurrentCheckedBox5(false);
  }

  return (
    <>
      {topComponent}

      <div className="mcp">
        <p>Er der særlige krav til emballagen?</p>
        <form>
          <label>
            <input
              name="check1"
              type="checkbox"
              checked={currentCheckedBox1}
              onChange={() => setCurrentCheckedBox1(!currentCheckedBox1)}
            />
            <TransparentLogo fill="none" stroke="#93BCC1" />
            Den skal være klar (gennemsigtig)
          </label>
          <label>
            <input
              name="check2"
              type="checkbox"
              checked={currentCheckedBox2}
              onChange={() => setCurrentCheckedBox2(!currentCheckedBox2)}
            />
            <LiquidLogo fill="none" stroke="#93BCC1" />
            Den skal kunne holde på væske (fx dressing, suppe eller sauce) i mindst 15 minutter
          </label>
          <label>
            <input
              name="check3"
              type="checkbox"
              checked={currentCheckedBox3}
              onChange={() => setCurrentCheckedBox3(!currentCheckedBox3)}
            />
            <CrispLogo fill="none" stroke="#93BCC1" />
            Den skal kunne bevare sprødhed i mindst 15 minutter, fordi indholdet fx er friturestegt
          </label>
        </form>
        <p>Skal din ret spises med det samme eller varmes op af din kunde derhjemme?</p>
        <div className="single-pick-buttons top-level">
          <button
            className={whenEaten === 'now' ? 'active' : ''}
            onClick={() => setWhenEaten('now')}
          >
            Spises med det samme
          </button>
          <button
            className={whenEaten === 'later' ? 'active' : ''}
            onClick={() => setWhenEaten('later')}
          >
            Opvarmes hjemme
          </button>
        </div>
        {whenEaten === 'now' && (
          <>
            <p>Hvor kold/varm er din ret, når du pakker den i emballagen?</p>
            <div className="single-pick-buttons">
              <Link to="/dcp">
                <button
                  className={`${currentFoodTemp === 'Frossen' ? 'active' : ''}`}
                  onClick={() => setTemperature('Frossen')}
                >
                  Frost
                </button>
              </Link>
              <Link to="/dcp">
                <button
                  className={`${currentFoodTemp === 'Kold' ? 'active' : ''}`}
                  onClick={() => setTemperature('Kold')}
                >
                  Køl til lav varme
                </button>
              </Link>
            </div>
          </>
        )}
        {whenEaten === 'later' && (
          <>
          <p>Hvordan skal retten opvarmes i emballagen?</p>
            <div className="single-pick-buttons">
              <Link to="/dcp">
                <button
                  className={`${currentFoodTemp === 'Stue' ? 'active' : ''}`}
                  onClick={() => {
                    setTemperature('Stue');
                    setFoodHeater('Mikroovn')
                  }}
                >
                  Mikroovn
                </button>
              </Link>
              <Link to="/dcp">
                <button
                  className={`${currentFoodTemp === 'Varm' ? 'active' : ''}`}
                  onClick={() => {
                    setTemperature('Varm');
                    setFoodHeater('Ovn')
                  }}
                >
                  Ovn
                </button>
              </Link>
              <Link to="/dcp">
                <button
                  className={`${currentCheckedBox5 ? 'active' : ''}`}
                  onClick={() => {
                    setCurrentCheckedBox5(true);
                    setFoodHeater('Grill')
                    setCurrentFoodTemp('');
                  }}
                >
                  Grill
                </button>
              </Link>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default MultipleChoicePicker
