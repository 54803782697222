import { Link, useHistory } from 'react-router-dom'
import { useEffect } from 'react'

import { useGlobalContext } from '../../context'
import './DiscardPicker.scss'

const DiscardPicker = ({ topComponent }) => {
  const { currentDiscardPick, setCurrentDiscardPick } = useGlobalContext()

  const history = useHistory()

  useEffect(() => {
    const keyPressHandler = (event) => {
      if (currentDiscardPick && event.key === 'Enter') {
        history.push('/shopper')
      }
    }

    window.addEventListener('keydown', keyPressHandler)
    return () => {
      window.removeEventListener('keydown', keyPressHandler)
    }
  })

  return (
    <>
      {topComponent}
      <div className="discard-picker">
        <h4>Hvor bliver din ret spist henne? Vælg så godt du kan.</h4>
        <p>
          Der er stor forskel i anbefalingen til hvilken emballagetype,
          du skal vælge, afhængig af om dine gæster kan sortere deres emballage
          til genanvendelse eller ej.
        </p>
        <div className="discard-pick-buttons">
          <div className="flex-buttons">
            
            <Link to="/shopper">
              <button
                className={`flex-btn long ${
                  currentDiscardPick === 'discardPick2' ? 'active' : ''
                }`}
                onClick={() => setCurrentDiscardPick('discardPick2')}
              >
                Et sted <span className="highlighted">med</span> affaldssortering - fx private hjem
              </button>
            </Link>
            
            <Link to="/shopper">
              <button
                className={`flex-btn long ${
                  currentDiscardPick === 'discardPick4' ? 'active' : ''
                }`}
                onClick={() => setCurrentDiscardPick('discardPick4')}
              >
                Et sted <span className="highlighted">uden</span> affaldssortering - fx offentlige parker
              </button>
            </Link>
            <Link to="/shopper">
              <button
                className={`flex-btn long ${
                  currentDiscardPick === 'discardPick5' ? 'active' : ''
                }`}
                onClick={() => setCurrentDiscardPick('discardPick5')}
              >
                Ved ikke
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default DiscardPicker
