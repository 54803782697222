import React, { useState, useEffect } from 'react'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { PdfDocument } from './PDFRenderer'

import { useGlobalContext } from '../../context'

import { ReactComponent as DownloadBtn } from '../../assets/images/download.svg'

const PDFReport = () => {
  const { cart } = useGlobalContext();
  const [materials, setMaterials] = useState(cart);

  useEffect(() => {
    setMaterials(cart)
  }, [cart])

  return (
    <div className="btn-download-wrapper">
      {
        <PDFDownloadLink
          document={<PdfDocument data={materials} />}
          fileName="DRCs_anbefalinger_til_takeaway_emballage.pdf"
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              <DownloadBtn className="btn-download" title="Download huskeseddel" /> // Possible to add loading icon here
            ) : (
              <DownloadBtn className="btn-download" title="Download huskeseddel" />
            )
          }
        </PDFDownloadLink>
      }
    </div>
  )
}

export default PDFReport
