import { Link } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'

import Hero from '../Hero'
import Namer from '../Namer'
import MultipleChoicePicker from '../MultipleChoicePicker'
import DiscardPicker from '../DiscardPicker'
import Shopper from '../Shopper'

import './Container.scss'
import slide1 from '../../assets/images/slide1.svg'
import slide2 from '../../assets/images/slide2.svg'
import slide3 from '../../assets/images/slide3.svg'
import slide4 from '../../assets/images/slide4.svg'
import backArrow from '../../assets/images/backArrow.svg'
import logo from '../../assets/images/logo.svg'
import { useGlobalContext } from '../../context'

const Container = () => {
  const { currentName } = useGlobalContext()

  return (
    <section className="container">
      <Switch>
        <Route exact path="/">
          <Hero
            topComponent={
              <>
                <div className="nav-buttons">
                  <div className="topleft">
                    <img src={logo} alt="logo" />
                  </div>
                </div>
                <h1>Takeaway-emballage-guiden</h1>
                <h3 className="subtitle">Din hjælp til at vælge de mest genanvendelige materialetyper</h3>
                <img className="slider" src={slide1} alt="slide1" />
              </>
            }
          />
        </Route>
        <Route path="/namer">
          <Namer
            topComponent={
              <>
                <div className="nav-buttons">
                  <Link to="/" className="topleft">
                    <img src={backArrow} alt="backArrow" />
                    Tilbage
                  </Link>
                  <div className="topright">
                    <p>Emballageanbefaling til {currentName}</p>
                  </div>
                </div>
                <h1>Takeaway-emballage-guiden</h1>
                <h3 className="subtitle">Din hjælp til at vælge de mest genanvendelige materialetyper</h3>
                <img className="slider" src={slide2} alt="slide2" />
              </>
            }
          />
        </Route>
        <Route path="/mcp">
          <MultipleChoicePicker
            topComponent={
              <>
                <div className="nav-buttons">
                  <Link to="/namer" className="topleft">
                    <img src={backArrow} alt="backArrow" />
                    Tilbage
                  </Link>
                  <div className="topright">
                    <p>Emballageanbefaling til {currentName}</p>
                  </div>
                </div>
                <h1>Takeaway-emballage-guiden</h1>
                <h3 className="subtitle">Din hjælp til at vælge de mest genanvendelige materialetyper</h3>
                <img className="slider" src={slide3} alt="slide3" />
              </>
            }
          />
        </Route>
        <Route path="/dcp">
          <DiscardPicker
            topComponent={
              <>
                <div className="nav-buttons">
                  <Link to="/mcp" className="topleft">
                    <img src={backArrow} alt="backArrow" />
                    Tilbage
                  </Link>
                  <div className="topright">
                    <p>Emballageanbefaling til {currentName}</p>
                  </div>
                </div>
                <h1>Takeaway-emballage-guiden</h1>
                <h3 className="subtitle">Din hjælp til at vælge de mest genanvendelige materialetyper</h3>
                <img className="slider" src={slide4} alt="slide4" />
              </>
            }
          />
        </Route>

        <Route path="/shopper">
          <Shopper
            topComponent={
              <>
                <div className="nav-buttons">
                  <Link to="/dcp">
                    <div className="topleft">
                      <img src={backArrow} alt="backArrow" />
                      Tilbage
                    </div>
                  </Link>
                  <div className="topright">
                    <p>Emballageanbefaling til {currentName}</p>
                  </div>
                </div>
                <h1>Takeaway-emballage-guiden</h1>
                <h3 className="subtitle">Din hjælp til at vælge de mest genanvendelige materialetyper</h3>
              </>
            }
          />
        </Route>
      </Switch>
    </section>
  )
}

export default Container
