import React, { useState, useContext, useEffect } from 'react'
import Papa from 'papaparse';

import csvData from './assets/csvData.csv';
import fallbackData from './assets/data.js'

const AppContext = React.createContext()

const AppProvider = ({ children }) => {
  const [currentName, setCurrentName] = useState('')
  const [currentCheckedBox1, setCurrentCheckedBox1] = useState(false)
  const [currentCheckedBox2, setCurrentCheckedBox2] = useState(false)
  const [currentCheckedBox3, setCurrentCheckedBox3] = useState(false)
  const [currentCheckedBox4, setCurrentCheckedBox4] = useState(false)
  const [currentCheckedBox5, setCurrentCheckedBox5] = useState(false)
  const [whenEaten, setWhenEaten] = useState('');
  const [foodHeater, setFoodHeater] = useState('');
  const [currentFoodTemp, setCurrentFoodTemp] = useState(null)
  const [currentRating, setCurrentRating] = useState('nonrecyclability')

  const [menuOpenState, setMenuOpenState] = useState(false)

  const [allMaterials, setAllMaterials] = useState(fallbackData)

  useEffect(() => {
    Papa.parse(csvData, {
      header: true,
      download: true,
      dynamicTyping: true,
      complete: (results) => {
        if (!results.data.length || results.errors.length) {
          return;
        }

        const requiredKeys = [
          'id',
          'simpletitle',
          'othernames',
          'nonrecyclability',
          'recyclability',
          'temperature',
          'transparencyavailable',
          'goodforliquid',
          'goodforcrispness',
          'goodforovenreheat',
          'readmoreurl',
          'img',
          'tempfrozen',
          'tempcold',
          'tempneutral',
          'tempwarm',
        ];

        // Ensure that all required keys exist on the first row.
        if (!requiredKeys.map(key => results.data[0].hasOwnProperty(key)).includes(false)) {
          setAllMaterials(results.data);
        }
      },
    });
  }, []);

  const [filteredMaterials, setFilteredMaterials] = useState([])
  useEffect(() => {
    let tempMaterials = allMaterials.filter(
      (material) =>
        (!currentCheckedBox1 ||
          (currentCheckedBox1 && material.transparencyavailable)) &&
        (!currentCheckedBox2 ||
          (currentCheckedBox2 && material.goodforliquid)) &&
        (!currentCheckedBox3 ||
          (currentCheckedBox3 && material.goodforcrispness)) &&
        (!currentCheckedBox4 ||
          (currentCheckedBox4 && material.goodforprint)) &&
        (!currentCheckedBox5 ||
          (currentCheckedBox5 && material.goodforovenreheat)) &&
        (!currentFoodTemp ||
          (currentFoodTemp === 'Frossen' && material.tempfrozen) ||
          (currentFoodTemp === 'Kold' && material.tempcold) ||
          (currentFoodTemp === 'Stue' && material.tempneutral) ||
          (currentFoodTemp === 'Varm' && material.tempwarm))
    );
    if (currentRating === 'nonrecyclability') {
      tempMaterials = tempMaterials.filter(material => material.nonrecyclability !== 0);
    }
    else {
      tempMaterials = tempMaterials.filter(material => material.recyclability !== 0);
    }

    setFilteredMaterials(tempMaterials)
  }, [
    allMaterials,
    currentCheckedBox1,
    currentCheckedBox2,
    currentCheckedBox3,
    currentCheckedBox4,
    currentCheckedBox5,
    currentFoodTemp,
    currentRating,
  ])

  const [currentlyEditing, setCurrentlyEditing] = useState(0)
  const [cart, setCart] = useState([])

  const [currentDiscardPick, setCurrentDiscardPick] = useState(null)
  useEffect(() => {
    if (
      currentDiscardPick === 'discardPick1' ||
      currentDiscardPick === 'discardPick2'
    ) {
      setCurrentRating('recyclability')
    } else {
      setCurrentRating('nonrecyclability')
    }
  }, [currentDiscardPick])

  const resetAllPicks = () => {
    setCurrentTitleOnEditing()
    setCurrentName('')
    setCurrentCheckedBox1(false)
    setCurrentCheckedBox2(false)
    setCurrentCheckedBox3(false)
    setCurrentCheckedBox4(false)
    setCurrentCheckedBox5(false)
    setWhenEaten('')
    setCurrentFoodTemp(null)
    setCurrentDiscardPick(null)
  }

  const setCurrentTitleOnEditing = () => {
    let existingSet = cart.find((cartSet) => cartSet.id === currentlyEditing)
    if (existingSet) {
      let tempCart = cart.map((cartSet) => {
        if (cartSet.id === currentlyEditing) {
          existingSet.title = currentName
          return existingSet
        }
        return cartSet
      })
      setCart(tempCart)
    }
  }

  const getSelectedOptions = () => {
    const selectedOptions = [];
    if (currentCheckedBox1) {
      selectedOptions.push("Emballagen skal være klar");
    }
    if (currentCheckedBox2) {
      selectedOptions.push("Emballagen skal kunne holde på væske i mindst 15 minutter");
    }
    if (currentCheckedBox3) {
      selectedOptions.push("Emballagen skal kunne bevare sprødhed i mindst 15 minutter");
    }
    if (whenEaten === 'now') {
      selectedOptions.push("Retten skal spises med det samme af din kunde");
      let text = currentFoodTemp;
      if (text === "Kold") {
        text = "Køl til lav varme";
      }
      selectedOptions.push(`Temperatur når du pakker din ret: ${text}`);
    }
    else if (whenEaten === 'later') {
      selectedOptions.push(`Din ret skal varmes op af din kunde i: ${foodHeater}`);
    }
    if (currentDiscardPick === 'discardPick2') {
      selectedOptions.push("Din ret spises: et sted med affaldssortering");
    }
    else if (currentDiscardPick === 'discardPick4') {
      selectedOptions.push("Din ret spises: et sted uden affaldssortering");
    }
    else {
      selectedOptions.push("Din ret skal spises: et sted hvor du ikke ved, om der er affaldssortering");
    }
    return selectedOptions;
  }

  const addToCart = (id, simpletitle, othernames, selectedOptions) => {
    let existingSet = cart.find((cartSet) => cartSet.id === currentlyEditing)
    if (existingSet) {
      let tempCart = cart.map((cartSet) => {
        if (cartSet.id === currentlyEditing) {
          let pickAlreadyInSet = cartSet.picks.find((pick) => pick.id === id)
          if (!pickAlreadyInSet) {
            let pick = {
              id,
              simpletitle,
              othernames,
              selectedOptions,
            }
            existingSet.picks.push(pick)
            return existingSet
          }
        }
        return cartSet
      })
      setCart(tempCart)
    } else {
      const newCartSet = {
        id: currentlyEditing,
        title: currentName,
        picks: [
          {
            id,
            simpletitle,
            othernames,
            selectedOptions,
          },
        ],
      }
      setCart((oldCart) => [...oldCart, newCartSet])
    }
  }

  const removeFromCart = (id) => {
    let tempCart = cart.filter((cartItem) => cartItem.id !== id)
    setCart(tempCart)
  }

  const removeFromSet = (id) => {
    let tempCart = cart
      .map((cartSet) => {
        if (cartSet.id === currentlyEditing) {
          let tempSet = cartSet.picks.filter((item) => item.id !== id)
          return { ...cartSet, picks: tempSet }
        }
        return cartSet
      })
      .filter((cartSet) => cartSet.picks.length > 0)
    setCart(tempCart)
  }

  const increaseEditingCounter = () => {
    setCurrentTitleOnEditing()
    setCurrentlyEditing(currentlyEditing + 1)
  }

  return (
    <AppContext.Provider
      value={{
        // Current title
        currentName,
        setCurrentName,
        // Checkbox settings
        currentCheckedBox1,
        currentCheckedBox2,
        currentCheckedBox3,
        currentCheckedBox4,
        currentCheckedBox5,
        setCurrentCheckedBox1,
        setCurrentCheckedBox2,
        setCurrentCheckedBox3,
        setCurrentCheckedBox4,
        setCurrentCheckedBox5,
        // When will the customer eat the food.
        whenEaten,
        setWhenEaten,
        // temperature settings
        currentFoodTemp,
        setCurrentFoodTemp,
        // What the food should be heated in, if eaten later.
        foodHeater,
        setFoodHeater,
        // discard settings
        currentDiscardPick,
        setCurrentDiscardPick,
        // Start over
        resetAllPicks,
        // Shopping
        cart,
        addToCart,
        removeFromCart,
        removeFromSet,
        currentlyEditing,
        increaseEditingCounter,
        setCurrentTitleOnEditing,
        // Materials list
        filteredMaterials,
        setFilteredMaterials,
        currentRating,
        setCurrentRating,
        // Sidebar
        isMenuOpen: menuOpenState,
        toggleMenu: () => setMenuOpenState(!menuOpenState),
        stateChangeHandler: (newState) => setMenuOpenState(newState.isOpen),
        getSelectedOptions,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useGlobalContext = () => {
  return useContext(AppContext)
}

export { AppContext, AppProvider }
