import React from 'react'
import { MemoryRouter as Router} from 'react-router-dom'

import './assets/reset.scss'
import './assets/global.scss'

import Container from './components/Container'
import Sidebar from './components/Sidebar'

function App() {

  return (
    <>
      <Router>
        <Sidebar pageWrapId={'main'} outerContainerId={'packaging-picker'} />
        <main id="main">
          <div id="main-content">
            <Container />
          </div>
        </main>
      </Router>
    </>
  )
}

export default App
