import React from 'react'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  Link,
} from '@react-pdf/renderer'

import logoBlack from '../../assets/images/logoBlack.png'
import footerLogo1 from '../../assets/images/recommendationLogo1.png';
import footerLogo2 from '../../assets/images/recommendationLogo2.png';
import footerLogo4 from '../../assets/images/recommendationLogo4.png';
import footerLogo5 from '../../assets/images/recommendationLogo5.png';

import knockoutFont from '../../assets/fonts/Knockout-HTF49-Liteweight.otf'
// import robotoFont from '../../assets/fonts/Roboto-Regular.ttf'

// font import issue described at https://github.com/diegomura/react-pdf/issues/875

Font.register({
  family: 'Knockout HTF49-Liteweight',
  src: knockoutFont,
})

const styles = StyleSheet.create({
  page: {
    margin: 50,
    paddingBottom: 350,
  },
  content: {
    marginBottom: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    height: 280,
    display: 'block',
    marginVertical: 10,
  },
  footerTitle: {
    fontSize: 28,
    fontFamily: 'Knockout HTF49-Liteweight',
    textTransform: 'uppercase',
  },
  logoContainer: {
    width: '80%',
    marginBottom: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  logo: {
    height: 40,
    width: 130,
  },
  footerLogo: {
    height: 115.13,
    width: 490,
    marginHorizontal: 6,
    marginBottom: 30,
  },
  title: {
    fontSize: 28,
    fontFamily: 'Knockout HTF49-Liteweight',
    textTransform: 'uppercase',
  },
  titleTwo: {
    fontSize: 28,
    fontFamily: 'Knockout HTF49-Liteweight',
    textTransform: 'uppercase',
    marginBottom: 20,
  },
  manchet: {
    width: 500,
    fontSize: 10,
  },
  section: {
    marginTop: '3mm',
    marginBottom: '3mm',
    display: 'block',
    fontSize: 16,
  },
  sectionTitle: {
    fontSize: 16,
    textTransform: 'uppercase',
    fontFamily: 'Knockout HTF49-Liteweight',
    marginTop: '8mm',
  },
  materials: {
    margin: 0,
    fontSize: 10,
  },
  footerItem: {
    marginTop: '4mm',
    flexDirection: 'row',
  },
  footerItemTextWrapper: {
    flexDirection: 'column',
    width: 450,
  },
  footerItemImage: {
    height: 30,
    width: 30,
  },
  footerItemTitle: {
    fontSize: 14,
    textTransform: 'uppercase',
    fontFamily: 'Knockout HTF49-Liteweight',
    paddingLeft: '4mm',
  },
  footerItemText: {
    fontSize: 10,
    paddingLeft: '4mm',
  }
})

export function PdfDocument(props) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View fixed style={styles.logoContainer}>
          <Image style={styles.logo} source={logoBlack} />
        </View>
        <Text style={styles.title}>
          Huskeseddel til mere miljø- og klimavenlig
        </Text>
        <Text style={styles.titleTwo}>
          take-away emballage
        </Text>
        <Text style={styles.manchet}>
          Brug denne huskeseddel over emballageanbefalinger, når du skal indkøbe emballage til din take away.
        </Text>

          {props.data
            ? props.data.map((a) => {
                return (
                  <View key={a.title}>
                    <Text style={styles.sectionTitle}>Emballageanbefaling til: {a.title}</Text>
                    <View style={styles.materials} wrap={false}>
                      <Text>Du har valgt:</Text>
                      {a.picks[0].selectedOptions.map((label, key) => <Text key={key}>- {label}</Text>)}
                      <Text> </Text>
                    </View>
                    {a.picks.map((pick, index) => {
                      return (
                        <View key={index} style={styles.materials} wrap={false}>
                          <Text>Materialetype: {pick.simpletitle}</Text>
                        </View>
                      )
                    })}
                  </View>
                )
              })
            : 'No items were found in your shopping list - If this is incorrect, please report this incident to the site owners'}
        <View fixed style={styles.footer}>
          <Text style={styles.footerTitle}>Husk altid de fire principper</Text>
          <View style={styles.footerItem}>
            <Image style={styles.footerItemImage} source={footerLogo1} />
            <View style={styles.footerItemTextWrapper}>
              <Text style={styles.footerItemTitle}>Reducér mængden af emballage</Text>
              <Text style={styles.footerItemText}>Hvis det ikke er muligt at undgå emballage, så brug så lidt som muligt.</Text>
            </View>
          </View>
          <View style={styles.footerItem}>
            <Image style={styles.footerItemImage} source={footerLogo4} />
            <View style={styles.footerItemTextWrapper}>
              <Text style={styles.footerItemTitle}>Vælg genbrugeligt materiale om muligt</Text>
              <Text style={styles.footerItemText}>Undersøg om der er genbrugelig løsning, der passer til dit koncept.</Text>
            </View>
          </View>
          <View style={styles.footerItem}>
            <Image style={styles.footerItemImage} source={footerLogo2} />
            <View style={styles.footerItemTextWrapper}>
              <Text style={styles.footerItemTitle}>Når sortering er mulig, vælg genanvendelig emballage:</Text>
              <Text style={styles.footerItemText}>Hvis en genbrugsløsning ikke er mulig, kan denne guide hjælpe dig med at finde emballage, der kan sorteres til genanvendelse. Følg anvisningerne <Link src="https://www.thehost.dk/emballageguide/">her</Link></Text>
            </View>
          </View>
          <View style={styles.footerItem}>
            <Image style={styles.footerItemImage} source={footerLogo5} />
            <View style={styles.footerItemTextWrapper}>
              <Text style={styles.footerItemTitle}>Når sorteringen ikke er mulig, så vælg fornybart materiale</Text>
              <Text style={styles.footerItemText}>Hvis kunden spiser måltidet ude, hvor affaldsortering sjældent er muligt, er fornybare materialer at foretrække.</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}
