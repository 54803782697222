import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { AppProvider } from './context'
const target = document.querySelector('#packaging-picker')

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>,
  target
)
